import React, { useContext } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard
import Home from "./components/Dashboard/Home";
import Instructors from "./components/Dashboard/Instructors";
import Profile from "./components/Dashboard/Profile";

//Courses----
import CoursesMain from "./components/Courses/CoursesMain";
import CourseDetail2 from "./components/Courses/CourseDetail2";

//Instructors
import InstructorDashboard from "./components/Instructor/InstructorDashboard";
import InstructorStudents from "./components/Instructor/InstructorStudents";


/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";

/// Charts
import SparklineChart from "./components/charts/Sparkline";
import ChartJs from "./components/charts/Chartjs";
import Chartist from "./components/charts/chartist";
import RechartJs from "./components/charts/rechart";
import ApexChart from "./components/charts/apexcharts";

/// Bootstrap
import UiAlert from "./components/bootstrap/Alert";
import UiAccordion from "./components/bootstrap/Accordion";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";

/// Plugins
import Select2 from "./components/PluginsMenu/Select2/Select2";
import MainNouiSlider from "./components/PluginsMenu/NouiSlider/MainNouiSlider";
import MainSweetAlert from "./components/PluginsMenu/SweetAlert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import JqvMap from "./components/PluginsMenu/JqvMap/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";

//Redux
import Todo from "./pages/Todo";

/// Widget
import Widget from "./pages/Widget";

/// Table
import SortingTable from "./components/table/SortingTable/SortingTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";
import DataTable from "./components/table/DataTable";
import BootstrapTable from "./components/table/BootstrapTable";

/// Form
import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
import SummerNote from "./components/Forms/Summernote/SummerNote";
import Pickers from "./components/Forms/Pickers/Pickers";
import jQueryValidation from "./components/Forms/jQueryValidation/jQueryValidation";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import { ThemeContext } from "../context/ThemeContext";
import ShowUsers from "./components/Users/ShowUsers";
import AddUser from "./components/Users/AddUser";
import AddCourse from "./components/Courses/AddCourse";
import ContactUsTable from "./components/Form/ContactUsTable";
import JoinUsTable from "./components/Form/JoinUsTable";
import DisplayAllUsers from "./components/Users/DisplayAllUsers";
import DisplayAllPayments from "./components/Payment/DisplayAllPayments";
import StudentProfile from "./components/Users/StudentProfile";
import TeacherHome from "./components/TeacherScreens/TeacherHome";
import TeacherCourseDetail from "./components/TeacherScreens/TeacherCourseDetail";
import QuizesMain from "./components/Quizes/QuizesMain";
import QuizeDetails from "./components/Quizes/QuizeDetails";
import PromoCodeMain from "./components/Promocode/PromoCodeMain";
import NewslistMain from "./components/Newslist/NewslistMain";
import InstractorProfile from "./components/AppsMenu/AppProfile/InstractorProfile";
import DiplomesMain from "./components/Diplomes/DiplomesMain";
import AddDiplomes from "./components/Diplomes/AddDiplomes";
import DiplomeDetail from "./components/Diplomes/DiplomeDetail";
import DisplayDiplomasPayments from "./components/Payment/DisplayDiplomasPayments";
import DisplayAllCourseSubscriptions from "./components/Subscriptions/DisplayAllCourseSubscriptions";
import DisplayAllDiplomasSubscriptions from "./components/Subscriptions/DisplayAllDiplomasSubscriptions";
import TeacherDiplomeDetail from "./components/TeacherScreens/TeacherDiplomeDetail";



import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import JobsPage from "./components/jobs/JobsPage";
import NewJobForm from "./components/jobs/NewJobForm";
import FeedbacksPage from "./components/feedbacks/FeedbacksPage";
import NewFeedbackForm from "./components/feedbacks/NewFeedbackForm";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    /// DASHBORD
    { url: "/", component: Home },
    { url: "dashboard", component: Home },
    { url: "admins", component: DisplayAllUsers },

    //COURSE
    { url: "courses", component: CoursesMain },
    { url: "add-courese/:inst_id", component: AddCourse },
    { url: "course-details-2/:_id", component: CourseDetail2 },
    //DIPLOMES
    { url: "diplomes", component: DiplomesMain },
    { url: "add-diplomes", component: AddDiplomes },
    { url: "diplome-detail/:_id", component: DiplomeDetail },

    //USERS
    { url: "users-dashbord", component: InstructorDashboard },
    { url: "instructors-all", component: Instructors },
    { url: "students", component: InstructorStudents },
    { url: "add-user", component: AddUser },
    { url: "app-profile/:user_id", component: AppProfile },
    { url: "instractor-profile/:user_id", component: InstractorProfile },
    { url: "student-profile/:user_id", component: StudentProfile },

    //FORMS
    { url: "contact", component: ContactUsTable },
    { url: "join-us", component: JoinUsTable },

    //payments
    { url: "payments/courses", component: DisplayAllPayments },
    { url: "payments/diplomes", component: DisplayDiplomasPayments },

    //subscriptions
    { url: "subscriptions/course", component: DisplayAllCourseSubscriptions },
    { url: "subscriptions/trackes", component: DisplayAllDiplomasSubscriptions },

    //Quizes
    { url: "quizes", component: QuizesMain },
    { url: "quizes-details/:_id", component: QuizeDetails },
    //promocode
    { url: "promocode", component: PromoCodeMain },
    //Newslist
    { url: "newslist", component: NewslistMain },
    
    //careers
    { url: "jobs", component: JobsPage },
    { url: "new-job", component: NewJobForm },

    //PAGES
    { url: "profile", component: Profile },
    { url: "page-login", component: Login },
    { url: "page-lock-screen", component: LockScreen },
    { url: "page-forgot-password", component: ForgotPassword },
    { url: "page-error-400", component: Error400 },
    { url: "page-error-403", component: Error403 },
    { url: "page-error-404", component: Error404 },
    { url: "page-error-500", component: Error500 },
    { url: "page-error-503", component: Error503 },
    
    
    { url: "reviews", component: FeedbacksPage },
    { url: "new-Review", component: NewFeedbackForm },

    // { url: "dashboard-dark", component: DashboardDark },
    // { url: "schedule", component: Schedule },
    // { url: "message", component: Message },
    // { url: "activity", component: Activity },
    //  { url: "profile", component: Profile },

    // Courses ----

    // { url: "course-details-1", component: CourseDetail1 },

    //Instructors  Pages path
    // { url: "instructor-dashboard", component: InstructorDashboard },
    // { url: "instructor-courses", component: InstructorCourses },
    // { url: "instructor-schedule", component: InstructorSchedule },
    // { url: "instructor-resources", component: InstructorResources },
    // { url: "instructor-students", component: InstructorStudents },
    // { url: "instructor-transactions", component: InstructorTransactions },
    // { url: "instructor-liveclass", component: LiveClass },

    ////Users

    /////Demo
    // { url: "secondary-sidebar", component: Theme1 },
    // { url: "mini-primary-sidebar", component: Theme2 },
    // { url: "nav-header", component: Theme3 },
    // { url: "horizontal-header", component: Theme4 },
    // { url: "header-nav", component: Theme5 },
    // { url: "sidebar-theme", component: Theme6 },
    // { url: "dark-sidebar", component: Theme7 },
    // { url: "theme-dashboard", component: Theme8 },

    /// Apps
    // { url: "email-compose", component: Compose },
    // { url: "email-inbox", component: Inbox },
    // { url: "email-read", component: Read },
    // { url: "app-calender", component: Calendar },
    // { url: "post-details", component: PostDetails },

    /// Chart
    // { url: "chart-sparkline", component: SparklineChart },
    // { url: "chart-chartjs", component: ChartJs },
    // { url: "chart-chartist", component: Chartist },
    // { url: "chart-apexchart", component: ApexChart },
    // { url: "chart-rechart", component: RechartJs },

    /// Bootstrap
    // { url: "ui-alert", component: UiAlert },
    // { url: "ui-badge", component: UiBadge },
    // { url: "ui-button", component: UiButton },
    // { url: "ui-modal", component: UiModal },
    // { url: "ui-button-group", component: UiButtonGroup },
    // { url: "ui-accordion", component: UiAccordion },
    // { url: "ui-list-group", component: UiListGroup },
    // { url: "ui-card", component: UiCards },
    // { url: "ui-carousel", component: UiCarousel },
    // { url: "ui-dropdown", component: UiDropDown },
    // { url: "ui-popover", component: UiPopOver },
    // { url: "ui-progressbar", component: UiProgressBar },
    // { url: "ui-tab", component: UiTab },
    // { url: "ui-pagination", component: UiPagination },
    // { url: "ui-typography", component: UiTypography },
    // { url: "ui-grid", component: UiGrid },

    /// Plugin
    // { url: "uc-select2", component: Select2 },
    // { url: "uc-noui-slider", component: MainNouiSlider },
    // { url: "uc-sweetalert", component: MainSweetAlert },
    // { url: "uc-toastr", component: Toastr },
    // { url: "map-jqvmap", component: JqvMap },
    // { url: "uc-lightgallery", component: Lightgallery },

    ///Redux
    // { url: "todo", component: Todo },

    /// Widget
    // { url: "widget-basic", component: Widget },

    // /// Shop
    // { url: "ecom-product-grid", component: ProductGrid },
    // { url: "ecom-product-list", component: ProductList },
    // { url: "ecom-product-detail", component: ProductDetail },
    // { url: "ecom-product-order", component: ProductOrder },
    // { url: "ecom-checkout", component: Checkout },
    // { url: "ecom-invoice", component: Invoice },
    // { url: "ecom-product-detail", component: ProductDetail },
    // { url: "ecom-customers", component: Customers },

    // /// Form
    // { url: "form-element", component: Element },
    // { url: "form-wizard", component: Wizard },
    // { url: "form-editor-summernote", component: SummerNote },
    // { url: "form-pickers", component: Pickers },
    // { url: "form-validation-jquery", component: jQueryValidation },

    // /// table
    // { url: "table-filtering", component: FilteringTable },
    // { url: "table-sorting", component: SortingTable },
    // { url: "table-datatable-basic", component: DataTable },
    // { url: "table-bootstrap-basic", component: BootstrapTable },

    /// pages
    // { url: "page-register", component: Registration },
  ];
  const teacherRoutes = [
    { url: "/", component: TeacherHome },
    { url: "dashboard", component: TeacherHome },
    { url: "app-profile/:user_id", component: AppProfile },
    { url: "course-details-2/:_id", component: TeacherCourseDetail },
    { url: "diploma-details-2/:_id", component: TeacherDiplomeDetail },
  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");

  const userData = JSON.parse(localStorage.getItem("userData")).data.role;
  console.log(userData);
  return (
    <>
      <ToastContainer/>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div
          className={`${!pagePath ? "content-body" : ""}`}
          style={{ minHeight: window.screen.height - 45 }}
        >
          <div className={`${!pagePath ? "container-fluid" : ""}`}>
            <Switch>
              {userData === "admin" ? (
                <>
                  {routes.map((data, i) => (
                    <Route
                      key={i}
                      exact
                      path={`/${data.url}`}
                      component={data.component}
                    />
                  ))}
                </>
              ) : (
                <>
                  {userData === "teacher" && (
                    <>
                      {teacherRoutes.map((data, i) => (
                        <Route
                          key={i}
                          exact
                          path={`/${data.url}`}
                          component={data.component}
                        />
                      ))}
                    </>
                  )}
                </>
              )}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;
