import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Badge,
  Button,
  Card,
  Col,
  Dropdown,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import swal from "sweetalert";
import { getAllPromocodeApi } from "../../../store/Slices/promocode/getAllPromocodeSlicer";
import PageTitle from "../../layouts/PageTitle";
import { createPromocodeApi } from "../../../store/Slices/promocode/createPromocodeSlicer";
import { handleError } from "../../../services/ErrorHandle";
import { updatePromocodeApi } from "../../../store/Slices/promocode/UpdatePromocodeSlicer";
import { deletePromocodeApi } from "../../../store/Slices/promocode/DeletePromocodeSlicer";
const svg1 = (
  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
    </g>
  </svg>
);
// const createQuize = Yup.object().shape({
//   enname: Yup.string()
//     .min(6, "quize name must consist of at least 6 characters ")
//     .max(50, "quize name must consist of at most 50 characters ")
//     .required("Please enter a quize name"),
//   arname: Yup.string()
//     .min(6, "quize name must consist of at least 6 characters ")
//     .max(50, "quize name must consist of at most 50 characters ")
//     .required("Please enter a quize name"),
//   endescription: Yup.string()
//     .min(6, "description  must consist of at least 6 characters ")
//     .max(200, "description must consist of at most 200 characters ")
//     .required("Please enter a  description"),
//   ardescription: Yup.string()
//     .min(6, "description  must consist of at least 6 characters ")
//     .max(200, "description must consist of at most 200 characters ")
//     .required("Please enter a  description"),
//   duration: Yup.number().required("Please enter a duration in min"),
//   number_of_attempts: Yup.number().required(
//     "Please enter a number of attempts "
//   ),
// });

const createPromoCode = Yup.object().shape({
  promocode: Yup.string()
    .required("Please enter a quize name")
    .matches(/^\S+$/, "Promocode Not Have Spaces"),
  discount: Yup.number().required("Please enter a discount"),
  maximum_usage: Yup.number().required("Please enter a max usage"),
});

function PromoCodeMain() {
  //dispatch
  const dispatch = useDispatch();

  //Selectors
  const {
    loading: getAllLoading,
    error: getAllError,
    response: getAllData,
  } = useSelector((state) => state.getAllPromocodeSlicer);

  const {
    loading: createLoading,
    error: createError,
    response: createData,
  } = useSelector((state) => state.createPromocodeSlicer);
  const {
    loading: updateLoading,
    error: updateError,
    response: updateData,
  } = useSelector((state) => state.UpdatePromocodeSlicer);

  const [promocodeData, setPromocodeData] = useState();

  // Create Modles
  const [createModle, setCreateModle] = useState(false);
  const [editModle, setEditModle] = useState(false);

  const [intialValues, setInitialValues] = useState({
    promocodeId: "",
    promocode: "",
    discount: 0,
    maximum_usage: 0,
  });

  const openEditModule = (data) => {
    console.log("datattatatt", data);
    setInitialValues({
      promocodeId: data._id,
      promocode: data.promocode,
      discount: data.discount,
      maximum_usage: data.maximum_usage,
    });
    setEditModle(true);
  };
  const handleCreatePromocode = (value, { resetForm }) => {
    const promocodeData = {
      promocode: value.promocode,
      discount: value.discount,
      maximum_usage: value.maximum_usage,
    };
    dispatch(createPromocodeApi(promocodeData)).then((res) => {
      if (res.payload.message === "Promocode created Successfully") {
        swal("Success", res.payload.message, "success");
        resetForm();
        setCreateModle(false);
        getAllDataFnction();
      } else {
        handleError(res.payload);
        // swal("Info", res.payload.message, "error");
      }
    });
  };
  const handleUpdatePromocode = (value, { resetForm }) => {
    const promocodeData = {
      promocodeId: value.promocodeId,
      data: {
        promocode: value.promocode,
        discount: value.discount,
        maximum_usage: value.maximum_usage,
      },
    };
    dispatch(updatePromocodeApi(promocodeData)).then((res) => {
      if (res.payload.message === "Promocode Updated Successfully") {
        swal("Success", res.payload.message, "success");
        resetForm();
        setEditModle(false);
        getAllDataFnction();
        setInitialValues({
          promocodeId: "",
          promocode: "",
          discount: 0,
          maximum_usage: 0,
        });
      } else {
        if (res.payload.data) {
          handleError(res.payload);
        } else {
          swal("Info", res.payload.message, "error");
        }
      }
    });
  };
  const getAllDataFnction = async () => {
    dispatch(getAllPromocodeApi());
  };

  const handleDeletePromocode = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deletePromocodeApi(id)).then((res) => {
          if (res.payload.message === "Data Deleted Successfully") {
            swal("Success", res.payload.message, "success");
            getAllDataFnction();
          } else {
            if (res.payload.data) {
              handleError(res.payload);
            } else {
              swal("Info", res.payload.message, "error");
            }
          }
        });
      } else {
        swal("Your imaginary file is safe!");
      }
    });
  };

  useEffect(() => {
    getAllDataFnction();
  }, []);
  useEffect(() => {
    getAllData && setPromocodeData(getAllData);
    console.log(getAllData);
  }, [getAllData]);

  //#region

  // const {
  //   loading: QuizesLoading,
  //   quizes: QuizesData,
  //   error: QuizesError,
  // } = useSelector((state) => state.getAllQuizesSlicer);

  // const { loading: createQuizesLoading, error: createQuizesError } =
  //   useSelector((state) => state.createQuizeSlicer);
  // const { loading: updateQuizesLoading, error: updateQuizesError } =
  //   useSelector((state) => state.updateQuizeSlicer);

  // const [createModle, setCreateModle] = useState(false);
  // const [editModle, setEditModle] = useState(false);
  // const [formIntilaData, setFormIntilaData] = useState({
  //   quizeId:'',
  //   enname: "",
  //   arname: "",

  //   endescription: "",
  //   ardescription: "",

  //   duration: 0,
  //   number_of_attempts: 0,
  // });
  // const handleCreateQuize = (value, { resetForm }) => {
  //   const quizeData = {
  //     name: {
  //       en: value.enname,
  //       ar: value.arname,
  //     },
  //     description: {
  //       en: value.endescription,
  //       ar: value.ardescription,
  //     },
  //     number_of_attempts: value.number_of_attempts,
  //     duration: value.duration,
  //   };
  //   dispatch(createQuizeApi(quizeData)).then((res) => {
  //     if (res.payload.message === "Quize created Successfully") {
  //       swal("Success", res.payload.message, "success");
  //       resetForm();
  //       setCreateModle(false);
  //       getAllQuizesfun();
  //     } else {
  //       swal("Info", res.payload.message, "error");
  //     }
  //   });
  // };

  // const handleEditQuize = (itemData) => {
  //   setFormIntilaData({
  //     quizeId:itemData._id,
  //     enname: itemData.name.en,
  //     arname: itemData.name.ar,

  //     endescription: itemData.description.en,
  //     ardescription: itemData.description.ar,

  //     duration: itemData.duration,
  //     number_of_attempts: itemData.number_of_attempts,
  //   });
  //   setEditModle(true);
  // };
  // const handleUpdateQuizeDataAPI = (value, { resetForm }) => {
  //   const quizId = value.quizeId;
  //   const quizeData = {
  //     name: {
  //       en: value.enname,
  //       ar: value.arname,
  //     },
  //     description: {
  //       en: value.endescription,
  //       ar: value.ardescription,
  //     },
  //     number_of_attempts: value.number_of_attempts,
  //     duration: value.duration,

  //   };

  //   dispatch(updateQuizeApi({quizeData,quizId})).then((res) => {
  //     if (res.payload.message === "Quize Updated Successfully") {
  //       swal("Success", res.payload.message, "success");
  //       resetForm();
  //       setEditModle(false);
  //       getAllQuizesfun();
  //     } else {
  //       swal("Info", res.payload.message, "error");
  //     }
  //   });
  // };

  // const [allQuizes, setAllQuizes] = useState([]);

  // const getAllQuizesfun = async () => {
  //   dispatch(getAllQuizesApi());
  // };

  // const handleDeleteQuize = (id) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You are going to delete this Quize",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, Delete it!!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       dispatch(deleteQuizeById(id)).then((res) => {
  //         if (res.payload.message === "Quize Deleted Successfully") {
  //           Swal.fire("Deleted!", res.payload.message, "success");
  //           getAllQuizesfun();
  //         }
  //       });
  //     } else {
  //       Swal.fire("Info!", "Quize Is Safe", "info");
  //     }
  //   });
  // };

  // useEffect(() => {
  //   getAllQuizesfun();
  // }, []);
  // useEffect(() => {
  //   QuizesData && setAllQuizes(QuizesData);
  // }, [QuizesData]);
  //#endregion
  return (
    <>
      <div className="widget-heading d-flex justify-content-between align-items-center">
        <h3 className="m-0">All Promocode</h3>
        <button
          onClick={() => setCreateModle(true)}
          className="btn btn-primary btn-sm"
        >
          Add New Code
        </button>
      </div>

      {getAllLoading ? (
        <>
          <div className="row d-flex justify-content-center gap-2">
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
          </div>
        </>
      ) : (
        <>
          {getAllError ? (
            <>
              <div className="row d-flex text-center gap-2">
                <h3>Error Happen </h3>
              </div>
            </>
          ) : (
            <>
              {promocodeData && promocodeData.length > 0 ? (
                <>
                  <Fragment>
                    <Row>
                      <Col lg={12}>
                        <Card>
                          <Card.Body>
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th className="width80">
                                    <strong>#</strong>
                                  </th>
                                  <th>
                                    <strong>Promocode</strong>
                                  </th>
                                  <th className="text-center">
                                    <strong>Maxmum Used</strong>
                                  </th>
                                  <th className="text-center">
                                    <strong>Number Used</strong>
                                  </th>
                                  <th>
                                    <strong>STATUS</strong>
                                  </th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {promocodeData.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <strong>{index + 1}</strong>
                                    </td>
                                    <td>{item.promocode}</td>
                                    <td className="text-center">{item.maximum_usage}</td>
                                    <td className="text-center">{item.num_usage}</td>
                                    <td>
                                      <Badge variant="success light">
                                        {item.discount} %
                                      </Badge>
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        <button
                                          href="#"
                                          className="btn btn-primary shadow btn-xs sharp me-1"
                                          onClick={() => openEditModule(item)}
                                        >
                                          <i className="fas fa-pencil-alt"></i>
                                        </button>
                                        <button
                                          onClick={() =>
                                            handleDeletePromocode(item._id)
                                          }
                                          className="btn btn-danger shadow btn-xs sharp"
                                        >
                                          <i className="fa fa-trash"></i>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Fragment>
                </>
              ) : (
                <>
                  <div className="row d-flex text-center gap-2">
                    <h3>No Data Added Yet</h3>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}

      {/* CreateQuize */}
      <Modal
        show={createModle}
        onHide={() => setCreateModle(false)}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Body>
          <Formik
            initialValues={{
              promocode: "",
              discount: 0,
              maximum_usage: 1,
            }}
            validationSchema={createPromoCode}
            onSubmit={handleCreatePromocode}
          >
            {({
              values,
              errors,
              setValues,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
            }) => (
              <form className="row" onSubmit={handleSubmit}>
                <div className="row">
                  <div
                    className={` col-lg-12 col-sm-12 form-group mb-3 ${
                      values.promocode
                        ? errors.promocode
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">Enter Promocode</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="fa-solid fa-file-signature"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter promocode.."
                        name="promocode"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.promocode}
                      />
                      <div
                        id="val-promocode-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.promocode && errors.promocode}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col-lg-6 col-sm-12 form-group mb-3 ${
                      values.discount
                        ? errors.discount
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">Enter Discount Value</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter a discount.."
                        name="discount"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.discount}
                      />
                      <div
                        id="val-discount-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.discount && errors.discount}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col-lg-6 col-sm-12 form-group mb-3 ${
                      values.maximum_usage
                        ? errors.maximum_usage
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">Enter Max Values</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter a max usge.."
                        name="maximum_usage"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.maximum_usage}
                      />
                      <div
                        id="val-maximum_usage-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.maximum_usage && errors.maximum_usage}
                      </div>
                    </div>
                  </div>
                </div>

                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setCreateModle(false)}
                  >
                    Close
                  </Button>
                  {createLoading ? (
                    <>
                      <Button variant="primary" disabled>
                        loading ...
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button variant="primary" type="submit">
                        Create Promocode
                      </Button>
                    </>
                  )}
                </Modal.Footer>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      {/* Edit Quize */}
      <Modal
        show={editModle}
        onHide={() => setEditModle(false)}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Body>
          <Formik
            initialValues={{
              promocodeId: intialValues.promocodeId,
              promocode: intialValues.promocode,
              discount: intialValues.discount,
              maximum_usage: intialValues.maximum_usage,
            }}
            validationSchema={createPromoCode}
            onSubmit={handleUpdatePromocode}
          >
            {({
              values,
              errors,
              setValues,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
            }) => (
              <form className="row" onSubmit={handleSubmit}>
                <div className="row">
                  <div
                    className={` col-lg-12 col-sm-12 form-group mb-3 ${
                      values.promocode
                        ? errors.promocode
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">Enter Promocode</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="fa-solid fa-file-signature"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter promocode.."
                        name="promocode"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.promocode}
                      />
                      <div
                        id="val-promocode-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.promocode && errors.promocode}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col-lg-6 col-sm-12 form-group mb-3 ${
                      values.discount
                        ? errors.discount
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">Enter Discount Value</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter a discount.."
                        name="discount"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.discount}
                      />
                      <div
                        id="val-discount-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.discount && errors.discount}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col-lg-6 col-sm-12 form-group mb-3 ${
                      values.maximum_usage
                        ? errors.maximum_usage
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">Enter Max Values</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter a max usge.."
                        name="maximum_usage"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.maximum_usage}
                      />
                      <div
                        id="val-maximum_usage-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.maximum_usage && errors.maximum_usage}
                      </div>
                    </div>
                  </div>
                </div>

                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setEditModle(false)}
                  >
                    Close
                  </Button>
                  {updateLoading ? (
                    <>
                      <Button variant="primary" disabled>
                        loading ...
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button variant="primary" type="submit">
                        Update Promocode
                      </Button>
                    </>
                  )}
                </Modal.Footer>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default PromoCodeMain;
